import React, { useLayoutEffect, useState } from 'react'
// import { AppContext } from './Common/Helpers/Context'
// import { useRouteMatch } from 'react-router-dom'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
// import {initGA, gaPageView} from './Common/utils/Tracking'
import ReactGA from "react-ga4"
import Home from './Components/Home/Home.js'
import Video from './Components/Video/VideoSinglePage.js'
import Page from './Components/Page/Page.js'
import Category from './Components/Category/Category.js'
import SearchResults from './Components/SearchResults/SearchResults.js'
import Playlist from './Components/Playlist/Playlist.js'

import 'bootstrap/dist/css/bootstrap.min.css'
import './Common/_assets/styles/main.scss'


export const App = () => {

    const [ s3Url ] = useState( "https://jwlfyc-public-us-east-1.s3.amazonaws.com/" )


    useLayoutEffect( () => {
        let subscribed = true
        if (subscribed) {
            ReactGA.initialize("G-VRBVQT742T");
            ReactGA.send("pageview");
        }
        return ()=>{subscribed = false}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <Router id="root" className="h-100">
        <Switch>
          <Route
            exact
            path='/'
            render={ (props) => {
              return (

                        <Home
                          s3Url={s3Url}
                          />

              )
            }}
            />
            <Route
              exact
              path={[
                      "/video",
                      "/video/:videoSlug"
                    ]}
              render={ (props) => {
                return (

                      <Video
                        s3Url={s3Url}
                        />

                )
              }}
              />
            <Route
              exact
              path={[
                      "/craft",
                      "/craft/:craftSlug"
                    ]}
              render={ (props) => {
                return (

                          <Category
                            s3Url={s3Url}
                           />

                )
              }}
              />
            <Route
              exact
              path={[
                      "/playlist",
                      "/playlist/:playlistSlug"
                    ]}
              render={ (props) => {
                return (

                      <Playlist
                        s3Url={s3Url}
                       />

                )
              }}
              />
              <Route
                exact
                path='/page'
                render={ (props) => {
                  return (

                          <Page />


                  )
                }}
                />
              <Route
                exact
                path={[
                        "/search",
                        "/search/:searchTerm"
                      ]}
                render={ (props) => {
                  return (

                          <SearchResults
                            s3Url={s3Url}
                           />

                  )
                }}
                />
        </Switch>
    </Router>
    );
}
