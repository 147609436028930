export const protectotron = (cb, fail) => {
    let req = new XMLHttpRequest()
    req.addEventListener('load', () => {
        try {
            let resp = JSON.parse(req.responseText)
            cb(resp.csrfToken)
        } catch(error) {
            fail(error)
        }
    })
    req.open('GET', '/api/auth/')
    req.setRequestHeader('Accept', 'application/json')
    req.setRequestHeader('Content-Type', 'application/json')
    req.send()
}

const _BASE_URI = '/api/v2'

export const get = (uri, cb:any, fail:any) => {
    let req = new XMLHttpRequest()
    req.addEventListener('load', () => {
        try {
            let response = JSON.parse(req.responseText)
            if(response.error) {
                console.log(response.message)
                fail(response.message)
            }
            else cb(response)
        } catch (error) {
            fail(error)
        }
    })
    req.addEventListener('error', (error) => {
        fail(error)
    })
    //console.log(_BASE_URI+uri)
    req.open('GET', _BASE_URI+uri)
    req.setRequestHeader('Accept', 'application/json')
    req.setRequestHeader('Content-Type', 'application/json')
    req.send()
}

export const get_secure = (uri:string, cb:any, fail:any) => {
    protectotron(token => {
        let req = new XMLHttpRequest()
        req.addEventListener('load', () => {
            try {
                let response = JSON.parse(req.responseText)
                cb(response)
            } catch(error) {
                fail(error)
            }
        })
        req.open('GET', _BASE_URI+uri)
        req.setRequestHeader('CSRF-Token', token)
        req.setRequestHeader('Accept', 'application/json')
        req.setRequestHeader('Content-Type', 'application/json')
        req.send()
    }, error => fail(error) )
}

export const put = (uri:string, data:any, cb:any, fail:any) => {
    protectotron(token => {
        let req = new XMLHttpRequest()
        req.addEventListener('load',() => {
            try {
                let response = JSON.parse(req.responseText)
                cb(response)
            } catch(error) {
                fail(error)
            }
        })
        req.addEventListener('progress', (event) => {
            switch(event.target.status) {
                case 500:
                    fail(JSON.parse(event.target.response))
                    break
                default: break
            }
        })
        req.open('PUT', _BASE_URI+uri)
        req.setRequestHeader('CSRF-Token', token)
        req.setRequestHeader('Accept', 'application/json')
        req.setRequestHeader('Content-Type', 'application/json')
        req.send(JSON.stringify(data))
    }, error => fail(error) )
}

export const post = (uri:string, data:any, cb:any, fail:any) => {
    protectotron(token => {
        let req = new XMLHttpRequest()
        req.addEventListener('load',(event) => {
            try {
                let response = JSON.parse(req.responseText)
                cb(response)
            } catch(error) {
                fail(error)
            }
        })

        req.addEventListener('progress', (event) => {
            switch(event.target.status) {
                case 500:
                    fail(JSON.parse(event.target.response))
                    break
                default: break
            }
        })
        req.open('POST', _BASE_URI+uri)
        req.setRequestHeader('CSRF-Token', token)
        req.setRequestHeader('Accept', 'application/json')
        req.setRequestHeader('Content-Type', 'application/json')
        req.send(JSON.stringify(data))
    }, error => fail(error) )
}

export const file = (uri:string, upload:any, progress:any, cb:any, fail:any) => {
    let size = 0
    size = upload.hasOwnProperty(size) ?
        size = upload.size
        : upload.forEach((file) => size+=file.size)
    protectotron(token => {
        let req = new XMLHttpRequest()

        req.upload.onload = (e) =>
            cb(req.responseText)

        req.upload.onprogress = (e) =>
            progress(parseFloat((e.loaded/e.total)*100).toPrecision(4))

        req.upload.onerror = (e) => fail(e)

        req.open('POST', _BASE_URI+uri, true)
        req.setRequestHeader('CSRF-Token', token)
        req.send(upload)
        progress(0)
    }, error => fail(error) )
}

export const del = (uri:string, cb:any, fail:any) => {
    protectotron(token => {
        let req = new XMLHttpRequest()
        req.addEventListener('load', () => {
            try {
                let response = JSON.parse(req.responseText)
                cb(response)
            } catch(error) {
                fail(error)
            }
        })
        req.open('DELETE', _BASE_URI+uri)
        req.setRequestHeader('CSRF-Token', token)
        req.setRequestHeader('Accept', 'application/json')
        req.setRequestHeader('Content-Type', 'application/json')
        req.send()
    }, error => fail(error) )
}

export const getSpotifyInfo = (type:string, key:string, cb:any, fail:any) => get('/spotify/'+type+'/'+key, cb, fail)

export const getYoutubeMedia = (key:string, cb:any, fail:any) => get('/youtube/'+key+'/', cb, fail)

const exports = {
    get: get,
    get_secure: get_secure,
    post: post,
    put: put,
    file: file,
    del: del,
    getSpotifyInfo: getSpotifyInfo,
    getYoutubeMedia: getYoutubeMedia
}

export default exports