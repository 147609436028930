import React from 'react'

import Header from '../../Common/Header/Header.js'
import Footer from '../../Common/Footer/Footer.js'

import imgPlaceholderSquare from '../../Common/_assets/img/placeholder/img-placeholder-square.jpg'

const Page = (props) => {

    return (

      <div class="wrapper d-flex flex-column h-100">

      <Header />

      <main className="px-3 px-xl-0 flex-fill d-flex mb-auto ">

        <div className="content width__ctrl align-self-stretch">

          <div className="width__ctrl bar-red"></div>

          <div className="py-4 d-flex no-gutters">

            <h1 className="fyseeinworld-pagetitle w-100 text-center my-4">THE WORLD OF FYSEE</h1>

          </div>


          <div className="py-4 d-flex">

              <div className="col-12 col-md-8 d-flex flex-wrap justify-content-center">

                <div className="col-12 mb-5 fyseeinworld-filtercontainer  d-flex justify-content-center">
                  <span className="fyseeinworld-filteritem active">Images</span>
                  <span className="fyseeinworld-filteritem ml-4">Video</span>
                  <span className="fyseeinworld-filteritem ml-4">Social</span>
                </div>

                <div className="col-6 col-md-3  fyseeinworld-griditem mb-4">
                  <a href="/#"><img src={imgPlaceholderSquare} alt="thumbnail" /></a>
                </div>
                <div className="col-6 col-md-3  fyseeinworld-griditem mb-4">
                  <a href="/#"><img src={imgPlaceholderSquare} alt="thumbnail" /></a>
                </div>
                <div className="col-6 col-md-3  fyseeinworld-griditem mb-4">
                  <a href="/#"><img src={imgPlaceholderSquare} alt="thumbnail" /></a>
                </div>
                <div className="col-6 col-md-3  fyseeinworld-griditem mb-4">
                  <a href="/#"><img src={imgPlaceholderSquare} alt="thumbnail" /></a>
                </div>
                <div className="col-6 col-md-3  fyseeinworld-griditem mb-4">
                  <a href="/#"><img src={imgPlaceholderSquare} alt="thumbnail" /></a>
                </div>
                <div className="col-6 col-md-3  fyseeinworld-griditem mb-4">
                  <a href="/#"><img src={imgPlaceholderSquare} alt="thumbnail" /></a>
                </div>
                <div className="col-6 col-md-3  fyseeinworld-griditem mb-4">
                  <a href="/#"><img src={imgPlaceholderSquare} alt="thumbnail" /></a>
                </div>
                <div className="col-6 col-md-3  fyseeinworld-griditem mb-4">
                  <a href="/#"><img src={imgPlaceholderSquare} alt="thumbnail" /></a>
                </div>
                <div className="col-6 col-md-3  fyseeinworld-griditem mb-4">
                  <a href="/#"><img src={imgPlaceholderSquare} alt="thumbnail" /></a>
                </div>
                <div className="col-6 col-md-3  fyseeinworld-griditem mb-4">
                  <a href="/#"><img src={imgPlaceholderSquare} alt="thumbnail" /></a>
                </div>
                <div className="col-6 col-md-3  fyseeinworld-griditem mb-4">
                  <a href="/#"><img src={imgPlaceholderSquare} alt="thumbnail" /></a>
                </div>
                <div className="col-6 col-md-3  fyseeinworld-griditem mb-4">
                  <a href="/#"><img src={imgPlaceholderSquare} alt="thumbnail" /></a>
                </div>

              </div>
              <div className="col-12 col-md-4">
                <h1 className="fyseeinworld-smalltitle">PRESS</h1>

                <div className="mb-3">
                  <div className="fyseeinworld-presstitle"><a href="/#">Lorem ipsum dolor sit amet</a></div>
                  <div className="fyseeinworld-pressname">Publication Name</div>
                </div>
                <div className="mb-3">
                  <div className="fyseeinworld-presstitle"><a href="/#">Lorem ipsum dolor sit amet</a></div>
                  <div className="fyseeinworld-pressname">Publication Name</div>
                </div>
                <div className="mb-3">
                  <div className="fyseeinworld-presstitle"><a href="/#">Lorem ipsum dolor sit amet</a></div>
                  <div className="fyseeinworld-pressname">Publication Name</div>
                </div>
                <div className="mb-3">
                  <div className="fyseeinworld-presstitle"><a href="/#">Lorem ipsum dolor sit amet</a></div>
                  <div className="fyseeinworld-pressname">Publication Name</div>
                </div>
                <div className="mb-3">
                  <div className="fyseeinworld-presstitle"><a href="/#">Lorem ipsum dolor sit amet</a></div>
                  <div className="fyseeinworld-pressname">Publication Name</div>
                </div>
                <div className="mb-3">
                  <div className="fyseeinworld-presstitle"><a href="/#">Lorem ipsum dolor sit amet</a></div>
                  <div className="fyseeinworld-pressname">Publication Name</div>
                </div>

              </div>

          </div>

        </div>

      </main>

      <Footer />

      </div>

    )
}

export default Page
