import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { App } from './App'
import * as serviceWorker from './serviceWorker'
import { JewelApp } from './Common/Jewel'

ReactDOM.render(
    <JewelApp show_format={ 'FILM' }>
        <Suspense fallback={ null }>
            <App />
        </Suspense>
    </JewelApp>,
    document.getElementById('root')
)

serviceWorker.unregister()
