import Api from '../utils/Api'

export const Guild = () => {
    return {
        list: (cb:any) => {
           Api.get('/guild',
               guilds => cb(guilds),
               () => {} )
        }
    }
}