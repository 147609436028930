import Api from '../utils/Api'

export const Media = (type:string) => {
    return {
        getMetaImage: (slug:string, meta:string, cb:any) => {
            Api.get('/'+type.toLowerCase()+'/'+slug+'/media/meta/'+meta,
                result => cb(result),
                () => {} )
        },
        getByTag: (slug:string, tag:string, cb:any) => {
            Api.get('/series/'+slug+'/media/tag/'+tag,
                result => cb(result),
                () => {} )
        },
        getByType: (slug:string, media_type:string, cb:any) => {
            Api.get('/'+type.toLowerCase()+'/'+slug+'/media/type/'+media_type,
                result => cb(result),
                () => {} )
        },
        getBySlug: (slug:string, cb:any) => {
            Api.get('/media/'+slug,
                result => cb(result),
                () => {} )
        }
    }
}
