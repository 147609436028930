import React, { useLayoutEffect } from 'react'
// import { AppContext } from '../../Helpers/Context'
// import { Event, TrackingEvents } from '../../Helpers/Tracking'
// import { Link } from 'react-router-dom'
// import { Row, Col } from 'react-bootstrap'
// import { PlaylistCtx } from '../../../Common/Context/PlaylistCtx'


const PlaylistMenu = (props) => {


    // const catNavClickLink = (genreName) => {
    //     subNavBlur()
    //     props.genreNameListener(genreName)
    //   Event(TrackingEvents.categories.INTERNAL_NAVIGATION, TrackingEvents.actions.NAV_CATEGORY_SUBNAV, TrackingEvents.labels.NAV_CATEGORY_SUBNAV)
    // }

    useLayoutEffect( () => {
      // let subscribed = true;
      // return ()=>{subscribed=false}
    }, [ props ])

    return (

      <div className="nav__bottom py-5 px-3 px-xl-0">

        <div className="d-flex width__ctrl flex-wrap no-gutters justify-content-center">


          <div className="col-12 text-center pb-3">
            <h3 className="text-red">Explore our artisans and talent</h3>
          </div>

          <div className="col-12 col-md-4 justify-content-center">
            <p className="text-center"><a href="/playlist/script-to-screen">Script to Screen</a></p>
            <p className="text-center"><a href="/playlist/the-making-of-squid-game">The Making of Squid Game</a></p>
            <p className="text-center"><a href="/playlist/creative-collaborations">Creative Collaborations</a></p>
          </div>
          <div className="col-12 col-md-4 justify-content-center">
            <p className="text-center"><a href="/playlist/in-conversation">In Conversation</a></p>
            <p className="text-center"><a href="/playlist/real-to-reel">Real to Reel</a></p>
            <p className="text-center"><a href="/playlist/the-backdrop">The Backdrop</a></p>
          </div>
          <div className="col-12 col-md-4 justify-content-center">
            <p className="text-center"><a href="/playlist/virtual-panels">Virtual Panels</a></p>
            <p className="text-center"><a href="/playlist/first-impressions">First Impressions</a></p>
            <p className="text-center"><a href="/playlist/fysee-more">FYSEE More</a></p>
          </div>

        </div>
      </div>

    )
}

export default PlaylistMenu
