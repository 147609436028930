import React from 'react'
// import { Link } from 'react-router-dom'
// import { Row, Col } from 'react-bootstrap'
// import { Event, TrackingEvents } from '../../Common/Helpers/Tracking'

import netflixLogo from '../../Common/_assets/img/logo/netflix-logo-ribbon.png'

const Footer = (props) => {
    return (
      <footer className="pt-4 pb-5 px-3 px-xl-0 mt-auto">

          <div className="d-none d-md-flex width__ctrl flex-wrap flex-md-nowrap no-gutters ">
            <div className="col-12 col-md-3 d-flex justify-content-center justify-content-md-start pb-4 pb-md-0"><a href="/"><img alt="Netflix" src={netflixLogo} className="logo mr-2"/> FYSEE TV</a></div>
            <div className="col-12 col-md-9 d-flex ml-auto justify-content-center justify-content-md-end">
              <div className="ml-5"><a href="https://series.netflixawards.com">FYC SITE</a></div>
              <div className="d-none ml-5"><a href="/">FYSEE 360</a></div>
            </div>
          </div>

          <div className="d-flex d-md-none width__ctrl flex-wrap flex-md-nowrap no-gutters ">
            <div className="col-12 col-md-3 d-flex justify-content-center justify-content-md-start pb-4 pb-md-0"><a href="/"><img alt="Netflix" src={netflixLogo} className="logo mr-2"/> FYSEE TV</a></div>
            <div className="col-12 col-md-9 d-flex ml-auto justify-content-center justify-content-md-end">
              <div className="ml-0"><a href="https://series.netflixawards.com">FYC</a></div>
              <div className="d-none ml-5"><a href="/">FYSEE 360</a></div>
            </div>
          </div>

          <div className="pt-3 pt-md-0 d-flex width__ctrl flex-wrap no-gutters">
            <div className="mt-3 col-12 d-flex ml-auto justify-content-center">
              <div className="ml-0 ml-md-5"><a href="https://netflix.com">Netflix.com</a></div>
              <div className="ml-0 ml-md-5"><a href="/">Privacy</a></div>
            </div>
            <div className="footer__disclaimer col-12 d-flex ml-auto justify-content-center">
              <div className="mt-3 mb-3">All uses of the Netflix For Your Consideration Website are subject to the terms of use associated with your promotional code and the Privacy Statement</div>
            </div>
          </div>

      </footer>
    )
}

export default Footer
