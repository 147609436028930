import Api from '../utils/Api'
import { Award } from './Award'
import { Guild } from './Guild'

export const People = () => {
    return {
        getCast: (slug:string, cb:any) => {
            Api.get('/show/'+slug+'/cast',
                result => cb(result),
                () => {} )
        },
        Award: Award(),
        Guild: Guild
    }
}