import React, { useLayoutEffect, useState, useContext } from 'react'
import {JewelCtx} from "../../Common/Jewel"
import $ from 'jquery'

import VideoListItem from './VideoListItem.js'

const VideoList = (props) => {

    const { Video } = useContext(JewelCtx)

    const [videos, setVideos] = useState(null),
          [allVideos, setAllVideos] = useState(null),
          [totalItemCount, setTotalItemCount] = useState(0),
          [currentSlice, setCurrentSlice] = useState(0),
          [currentPageNum, setCurrentPageNum] = useState(0)

    const numberOfPageItems = 12
    const totalNumberOfPages = ((totalItemCount < (numberOfPageItems*2)) && (totalItemCount > numberOfPageItems))?(2):(Math.ceil(totalItemCount/numberOfPageItems))

    useLayoutEffect( () => {
        let subscribed = true;
        if (subscribed)
            Video('SERIES').list(masterVideoList => {
              // console.log('videos', masterVideoList)
              let sliceAt = currentSlice
              let numOfItems = numberOfPageItems
              let range = sliceAt + numOfItems
              let slice = masterVideoList.slice(sliceAt, range)
              setVideos(slice)
              setAllVideos(masterVideoList)
              setTotalItemCount(Object.keys(masterVideoList).length)
            })
        return ()=>{subscribed = false}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [  ])


    const paginate = (sliceAt) => {
      let numOfItems = numberOfPageItems
      let range = sliceAt + numOfItems
      let slice = allVideos.slice(sliceAt, range)
      setVideos(slice)
      $('html,body').animate({ scrollTop: 450 }, 'slow');
      // console.log(totalItemCount, "Num of items: "+numOfItems, "range: "+range, "sliceAt: "+sliceAt,"currentSlice: "+currentSlice, "totalNumberOfPages: "+totalNumberOfPages, currentPageNum )
    }


    return (

        <div className="content">

          <div className="videolist d-flex width__ctrl flex-wrap row py-4 no-gutters">

            {(videos)?(
              videos.map( (v, i) => {
                return (
                    <VideoListItem
                      video={v}
                      key={i}
                      s3Url={props.s3Url}
                      />
                )
              })
            ):("")}

          </div>

          <nav className="" aria-label="Page navigation">
            <ul className="pagination pagination-template d-flex justify-content-center">


              <li className="page-item" style={{
                display:currentPageNum > 0 ? "block" : "none"
              }}><a onClick={(e)=>{
                e.preventDefault()
                let goTo = (currentSlice === 0)?(0):(currentSlice - numberOfPageItems)
                setCurrentSlice(goTo)
                setCurrentPageNum((currentPageNum===0)?(0):(currentPageNum-1))
                paginate(goTo)
              }} className="page-link" href="/">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><polyline points="15 18 9 12 15 6"></polyline></svg>
              </a></li>

              {[...Array(totalNumberOfPages)].map((x, i) =>
                <li key={i} className={"page-item "+((i===currentPageNum)?("active"):(""))}>
                  <a onClick={(e)=>{
                    e.preventDefault()
                    let goTo = numberOfPageItems*i
                    setCurrentSlice(goTo)
                    setCurrentPageNum(i)
                    paginate(goTo)
                  }} className="page-link" href="/">{i+1}</a>
                </li>
              )}


              <li className="page-item" style={{
                display:(currentPageNum === (totalNumberOfPages-1)) ? "none" : "block"
              }}><a onClick={(e)=>{
                e.preventDefault()
                let goTo = (currentSlice + numberOfPageItems)
                setCurrentSlice(goTo)
                setCurrentPageNum(currentPageNum+1)
                paginate(goTo)
              }} className="page-link" href="/">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><polyline points="9 18 15 12 9 6"></polyline></svg>
              </a></li>
            </ul>
          </nav>


        </div>

    )
}

export default VideoList
