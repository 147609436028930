import React, { useLayoutEffect, useState } from 'react'
// import {JewelCtx} from "../../../Common/Jewel"

const VideoShowItem = ({show, i, isLinked}) => {

    // const { Show } = useContext(JewelCtx)
    const [showTitle, setShowTitle] = useState(null),
          [showSlug, setShowSlug] = useState(null),
          [showCategorySlug, setShowCategorySlug] = useState(null)

    useLayoutEffect( () => {
        let subscribed = true;
        if (subscribed && show) {

          let title = show.title.replace(' S1,','')
                                  .replace(' S1','')
                                  .replace(' S2,','')
                                  .replace(' S2','')
                                  .replace(' S3,','')
                                  .replace(' S3','')
                                  .replace(' S4,','')
                                  .replace(' S4','')
                                  .replace(' S5,','')
                                  .replace(' S5','')
                                  .replace(' S6,','')
                                  .replace(' S6','')
                                  .replace(' S7','')

          setShowTitle(title)
          setShowSlug(show.slug)

          // this should be the slug of the first [0] return from the show's categories
          setShowCategorySlug(show.genre_slug)

        } return ()=>{subscribed = false}
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    return (
      <>
        {
          !isLinked ?
            <span className="text-red font-weight-bold">{i>0?', ':''}{showTitle}</span>
          :
          <a target="_blank" rel="noopener noreferrer" href={"https://series.netflixawards.com/"+showCategorySlug+"/"+showSlug} >
            <span className="text-red font-weight-bold">{i>0?', ':''}{showTitle}</span>
          </a>
        }
      </>
    )
}

export default VideoShowItem