import React, { useLayoutEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
// import { VideoCtx } from '../../Common/Context/VideoCtx'
// import { ShowCtx } from '../../Common/Context/ShowCtx'

import VideoListItem from '../Video/VideoListItem.js'
// import ShowProvider from "../../Common/Context/ShowCtx";

const SearchResultsVideoList = ({s3Url}) => {

    // const { Video } = useContext(VideoCtx)

    const match = useRouteMatch(),
          [videos, setVideos] = useState([])

    useLayoutEffect( () => {
        let subscribed = true;
        if (subscribed) {
          setVideos("")
        } return ()=>{subscribed = false}
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useLayoutEffect( () => {
        let subscribed = true;
        if (subscribed) {
          console.log("Search Term from url: ",match.params.searchTerm)
            // Video.search(match.params.searchTerm, (videos) => {
            //     console.log("Filtered videos: ", videos)
            //     setVideos(videos)
            // })
        } return ()=>{subscribed = false}
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [s3Url, match.params.searchTerm])


    return (

        <div className="content">
          <div className="d-flex width__ctrl flex-wrap row py-4 no-gutters">

            {(videos)?(
              videos.map( (v, i) => {
                return (
                    <VideoListItem
                      video={v}
                      key={i}
                      s3Url={s3Url}
                      />
                )
              })
            ):("")}

          </div>
        </div>

    )
}

export default SearchResultsVideoList
