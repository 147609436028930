import Api from '../utils/Api'

export const Award = () => {
    return {
        getShowAwards: (slug:string, cb:any) => {
            Api.get('/show/'+slug+'/award',
                result => cb(result),
                () => {} )
        },
        getCastAwards: (slug:string, people_id:number, cb:any) => {
            Api.get('/show/'+slug+'/cast/'+people_id+'/award',
                result => cb(result),
                () => {} )
        }
    }
}