import Api from '../utils/Api'

export const Video = (format) => {
    return {
        list: (cb: any) => {
            Api.get('/' + format.toLowerCase()+'/video',
                video => cb(video),
                error => console.error(error) )
        },
        listShowVideos: (slug:string, cb: any) => {
            Api.get('/' + format.toLowerCase()+'/'+slug+'/video',
                video => cb(video),
                error => console.error(error) )
        },
        load: (slug:string, cb:any) => {
            Api.get('/'+format.toLowerCase()+'/video/'+slug,
                video => cb(video),
                error => console.log(error) )
        },
        search: (keyword:string, cb:any) => {
            Api.put('/'+format.toLowerCase()+'/video/search',
                { keyword: keyword },
                video => cb(video),
                error => console.log(error))
        }
    }
}
