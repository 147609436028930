import React, { useLayoutEffect, useState, useContext } from 'react'
// import { useRouteMatch } from 'react-router-dom'
import {JewelCtx} from "../../Common/Jewel"
import VideoShowItem from "./Components/VideoShowItem"
// import {OverlayTrigger, Tooltip} from 'react-bootstrap'

import videoThumbPlaceholder from '../../Common/_assets/img/placeholder/videoplaceholderblank.gif'


const VideoListItem = ({video, s3Url}) => {

    const { Media } = useContext(JewelCtx),
          { Craft } = useContext(JewelCtx),
          { Playlist } = useContext(JewelCtx),
          { Show } = useContext(JewelCtx)

    const [videoShows, setVideoShows] = useState(null),
          [videoDesc, setVideoDesc] = useState(null),
          [videoPlaylists, setVideoPlaylists] = useState(null),
          [videoCrafts, setVideoCrafts] = useState(null),
          [videoThumbnail, setVideoThumbnail] = useState(null)

    const truncate = (str, n) => {
      return (str.length > n) ? str.substr(0, n-1) + ' ... ' : str;
    }


    useLayoutEffect( () => {
        let subscribed = true;
        if (subscribed) {

          let desc = video.description ? truncate(video.description, 144) : ""
          setVideoDesc(desc)


        } return ()=>{subscribed = false}
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [video])


      useLayoutEffect( () => {
          let subscribed = true;
          if (subscribed && video.slug) {


              Media('VIDEO').getMetaImage(video.slug, 'thumbnail', image => {
                  // console.log('img',video.slug,video, image)
                  if(image && image.show_image) {
                    setVideoThumbnail(s3Url+image.show_image)
                  }
              })

              Show('SERIES').getShowsByVideoSlug(video.slug, s => {
                  // console.log('s',video.slug,video, s)
                // console.log('getShowsByVideoSlug', video.slug, s)
                var result = s.reduce((unique, o) => {
                    if(!unique.some(obj => obj.show_id === o.show_id)) {
                      unique.push(o);
                    }
                    return unique;
                },[])
                setVideoShows(result)
              })
              Craft('SERIES').getCrafts(video.slug, c => {
                  // console.log('c',video.slug,video, c)
                // console.log('getCrafts', video.slug, c)
                setVideoCrafts(c)
              })
              Playlist('SERIES').getLists(video.slug, p => {
                  // console.log('p',video.slug,video, p)
                // console.log('getLists', video.slug, p)
                setVideoPlaylists(p[Object.keys(p).length-1])
              })


          } return ()=>{subscribed = false}
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [s3Url, video.slug])



    return (

      <a href={"/video/"+video.slug} className="videolistitem col-12 col-sm-6 col-md-4">
        <div className="videolistitem-inner mb-4">
          <div className="col-12 position-relative" style={{overflow:'hidden'}}>

            {
              videoThumbnail ?
                <div className="col videothumb">
                  <img alt="Netflix" src={videoThumbnail} className=""/>
                </div>
              :""
            }

            <img alt="Netflix" src={videoThumbPlaceholder} className="videothumb-placeholder"/>

            <div className="videolistitem-description">
              <div className="videolistitem-description-inner h-100">
                <h4>{video.title}</h4>
                {
                  videoDesc ?
                    <span dangerouslySetInnerHTML={{ __html: videoDesc }} />
                  :""
                }
              </div>
            </div>

          </div>

          <div className="col-12 d-flex justify-content-start align-items-start  pt-2">


          <div className="">


          {
            videoShows ?
              videoShows.map((s,i) => {
                return <VideoShowItem key={i} i={i} show={s} isLinked={false} />
              })
            :""
          }


              { videoShows ?
                  <span className=" text-muted">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                :""
              }

              {
                videoPlaylists ?
                  <span className="font-weight-bold">
                    {videoPlaylists.name}
                  </span>
                :""
              }


              <div className="text-muted  label-small w-100">
                {(videoCrafts)?(
                  videoCrafts.map( (c, i) => {
                    return (
                      <span key={i} className="">
                        {(i===0)?(""):(", ")}
                        {c.name}
                      </span>
                    )
                  })
                ):("")}

              </div>

            </div>

            <div className="ml-auto text-muted label-small text-right">




            </div>


          </div>
          <div className="col-12 d-none justify-content-start pt-1 pb-3"><h4>{video.title}</h4></div>
        </div>
      </a>

    )
}

export default VideoListItem
