import Api from '../utils/Api'

export const Venue = () => {
    return {
        list: (event_slug:string, cb:any) => {
            Api.get('/event/'+event_slug+'/venue',
                venues => cb(venues),
                () => {} )
        }
    }
}