import React, { createContext } from 'react'

import { Event } from './lib/Event'
import { People } from './lib/People'
import { Show } from './lib/Show'
import { Video } from './lib/Video'
import { Craft } from './lib/Craft'
import { Playlist } from './lib/Playlist'
import { Media } from './lib/Media'

export const JewelCtx = createContext({})

export const JewelApp = ({ children }) => {

    return (
        <JewelCtx.Provider value={{
            Event: Event,
            People: People,
            Show: Show,
            Video: Video,
            Playlist: Playlist,
            Craft: Craft,
            Media: Media
        }}>
            { children }
        </JewelCtx.Provider>
    )
}
