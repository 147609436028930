import React, { useLayoutEffect, useState, useContext } from 'react'
import {JewelCtx} from "../../Common/Jewel"
import { useRouteMatch } from 'react-router-dom'
import $ from 'jquery'

import VideoListItem from './VideoListItem.js'

const PlaylistVideoList = (props) => {

    const { Playlist } = useContext(JewelCtx)

    const match = useRouteMatch(),
          [videos, setVideos] = useState(null),
          [allVideos, setAllVideos] = useState(null),
          [totalItemCount, setTotalItemCount] = useState(0),
          [currentSlice, setCurrentSlice] = useState(0),
          [currentPageNum, setCurrentPageNum] = useState(0)


    const numberOfPageItems = 12
    const totalNumberOfPages = ((totalItemCount < (numberOfPageItems*2)) && (totalItemCount > numberOfPageItems))?(2):(Math.ceil(totalItemCount/numberOfPageItems))


    useLayoutEffect( () => {
        let subscribed = true;
        if (subscribed) {
          // console.log(match.params)
          let currentSlug = match.params.playlistSlug
          if (currentSlug) {
            Playlist('SERIES').getVideos(currentSlug, masterVideoList => {
              // console.log('videos', masterVideoList)
              let sliceAt = currentSlice
              let numOfItems = numberOfPageItems
              let range = sliceAt + numOfItems
              let slice = masterVideoList.slice(sliceAt, range)
              setVideos(slice)
              setAllVideos(masterVideoList)
              setTotalItemCount(Object.keys(masterVideoList).length)
            })
          }
        } return ()=>{subscribed = false}
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match])


    const paginate = (sliceAt) => {
      let numOfItems = numberOfPageItems
      let range = sliceAt + numOfItems
      let slice = allVideos.slice(sliceAt, range)
      setVideos(slice)
      $('html,body').animate({ scrollTop: 0 }, 'slow');
      console.log(totalItemCount, "Num of items: "+numOfItems, "range: "+range, "sliceAt: "+sliceAt,"currentSlice: "+currentSlice, "totalNumberOfPages: "+totalNumberOfPages )
    }

    return (

        <div className="content">
          <div className="d-flex width__ctrl flex-wrap row py-4 no-gutters">

            <h3 className="mb-5 mt-2 pl-0 pl-md-3 videolist__title font-weight-bold w-100">Playlist: {match.params.playlistSlug.replace(/-/g, ' ')}</h3>
            <div className="videolist d-flex  flex-wrap row no-gutters">
            {(videos)?(
              videos.map( (v, i) => {
                return (
                    <VideoListItem
                      key={i}
                      video={v}
                      s3Url={props.s3Url}
                      />
                )
              })
            ):("")}
            </div>


            {(videos && !videos.length > 0)?(
              <div className="mb-5 mt-2 pl-0 pl-md-3 font-weight-bold w-100">This playlist doesn't contain any videos yet. Please <a className="text-red" href="/">browse the full selection</a> and check back here for more content!</div>
            ):("")}

          </div>

          {(totalItemCount > numberOfPageItems)?(


            <nav className="" aria-label="Page navigation">
              <ul className="pagination pagination-template d-flex justify-content-center">


                <li className="page-item" style={{
                  display:currentPageNum > 0 ? "block" : "none"
                }}><a onClick={(e)=>{
                  e.preventDefault()
                  let goTo = (currentSlice === 0)?(0):(currentSlice - numberOfPageItems)
                  setCurrentSlice(goTo)
                  setCurrentPageNum((currentPageNum===0)?(0):(currentPageNum-1))
                  paginate(goTo)
                }} className="page-link" href="/">
                  <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><polyline points="15 18 9 12 15 6"></polyline></svg>
                </a></li>

                {[...Array(totalNumberOfPages)].map((x, i) =>
                  <li key={i} className={"page-item "+((i===currentPageNum)?("active"):(""))}>
                    <a onClick={(e)=>{
                      e.preventDefault()
                      let goTo = numberOfPageItems*i
                      setCurrentSlice(goTo)
                      setCurrentPageNum(i)
                      paginate(goTo)
                    }} className="page-link" href="/">{i+1}</a>
                  </li>
                )}


                <li className="page-item" style={{
                  display:(currentPageNum === (totalNumberOfPages-1)) ? "none" : "block"
                }}><a onClick={(e)=>{
                  e.preventDefault()
                  let goTo = (currentSlice + numberOfPageItems)
                  setCurrentSlice(goTo)
                  setCurrentPageNum(currentPageNum+1)
                  paginate(goTo)
                }} className="page-link" href="/">
                  <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><polyline points="9 18 15 12 9 6"></polyline></svg>
                </a></li>
              </ul>
            </nav>

          ):("")}

        </div>

    )
}

export default PlaylistVideoList
