import React, { useState, useLayoutEffect, useContext } from 'react'
import {JewelCtx} from "../../Common/Jewel"
import OutsideClickHandler from 'react-outside-click-handler'

import {Dropdown} from 'react-bootstrap'

import netflixLogo from '../../Common/_assets/img/logo/netflix-logo-ribbon.png'
import spinner from '../../Common/_assets/img/placeholder/spinner.gif'

const Header = (props) => {

  const { Video } = useContext(JewelCtx)

  const [isSearching, setIsSearching] = useState(false),
        [resultVideos, setResultVideos] = useState(null),
        [searchQuery, setSearchQuery] = useState("")

  // const { Video } = useContext(VideoCtx)

  const searchVideos = (s) => {

    console.log("search: ", s)

    if (s.length > 1) {

      Video('SERIES').search(s, (videos) => {
          console.log("search result: ", s, videos)
          setResultVideos(videos)
      })

    } else {
      setResultVideos(null)
    }
  }

  useLayoutEffect( () => {
      let subscribed = true;
      if (subscribed)
          setResultVideos(null)
      return ()=>{subscribed = false}
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [  ])



    return (
      <header className="site-header py-3 px-3 px-xl-0">
        <div className="d-flex width__ctrl flex-nowrap no-gutters align-items-center">
          <div className="col-3"><a className="site-title" href="/"><img alt="Netflix" src={netflixLogo} className="logo mr-2"/> FYSEE TV</a></div>
          <div className="col-12 col-md-9 d-flex width__ctrl ml-auto justify-content-center justify-content-md-end align-items-center">

          <Dropdown>
            <Dropdown.Toggle variant="success" className="header-dropdown-container d-flex align-items-center">
                <span className="d-none d-md-inline">BROWSE CRAFTS</span>
                <span className="d-inline d-md-none">CRAFTS</span>
                <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="ml-1 css-i6dzq1"><polyline points="6 9 12 15 18 9"></polyline></svg>
            </Dropdown.Toggle>

            <Dropdown.Menu className="header-dropdown-menu">
              <Dropdown.Item href="/craft/directing">Directing</Dropdown.Item>
              <Dropdown.Item href="/craft/acting">Acting</Dropdown.Item>
              <Dropdown.Item href="/craft/animation">Animation</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown>
            <Dropdown.Toggle variant="success" className="header-dropdown-container d-flex align-items-center ml-2 ml-md-5">
                <span className="d-none d-md-inline">BROWSE PLAYLISTS</span>
                <span className="d-inline d-md-none">PLAYLISTS</span>
                <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="ml-1 css-i6dzq1"><polyline points="6 9 12 15 18 9"></polyline></svg>
            </Dropdown.Toggle>

            <Dropdown.Menu className="header-dropdown-menu">
              <Dropdown.Item href="/playlist/in-conversation">In Conversation</Dropdown.Item>
              <Dropdown.Item href="/playlist/virtual-panels">Virtual Panels</Dropdown.Item>
              <Dropdown.Item href="/playlist/fysee-more">FYSEE More</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>


            <a className="ml-2 ml-md-5" href="https://series.netflixawards.com">
              <span className="d-none d-md-block">FYC SITE</span>
              <span className="d-block d-md-none">FYC</span>
            </a>

            <OutsideClickHandler onOutsideClick={() => {setIsSearching(false)}}>
              <div className="d-none d-md-flex ml-2 ml-md-5 position-relative">

                <form className="form-inline d-block d-sm-flex" action={"/search/"} autoComplete="off" id="search">
                  <div className="input-label-absolute input-label-absolute-left input-reset input-expand ml-lg-2 ml-xl-3">
                    <input onChange={(e)=>{
                        searchVideos(e.target.value)
                        setSearchQuery(e.target.value)
                    }}
                    onFocus={()=>{setIsSearching(true)}}
                    onBlur={()=>{}}
                    style={{backgroundColor:'#888',width:"200px"}} className="form-control form-control-sm border-0 shadow-0 bg-gray-200" id="search_search" placeholder="Search FYSEE TV" aria-label="Search" />
                  </div>
                </form>


                <div align={"left top"} className={"searchresults-dropdown "+((isSearching && (searchQuery.length > 1))?(""):("d-none"))}>
                  {(resultVideos)?(
                    <>
                      {(resultVideos.length > 0)?(
                        resultVideos.map( (v, i) => {
                          return (
                            <a href={"/video/"+v.slug} key={i}>{v.title}</a>
                          )
                        })
                      ):(
                        <div className="searchresults-no-results">No Results Found</div>
                      )}
                    </>


                  ):(
                    <div>
                      {(searchQuery.length > 1)?(
                          <img alt="Searching" src={spinner} style={{width:"30px",display:"block",margin:"0 auto"}}/>
                      ):("")}

                    </div>
                  )}
                </div>

              </div>
            </OutsideClickHandler>
          </div>
        </div>




      </header>
    )
}

export default Header
