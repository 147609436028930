import React, {useState, useLayoutEffect, useRef, useContext} from 'react'
// import { Event, TrackingEvents } from '../../Common/Helpers/Tracking'
import {JewelCtx} from "../../Common/Jewel"
import { useRouteMatch } from 'react-router-dom'
import VideoShowItem from "./Components/VideoShowItem"

import $ from 'jquery'
import YouTube from 'react-youtube'

// import thumbPlaceholder from "../../Common/Assets/img/videothumb.jpg"
import videoPlaceholderBlank from '../../Common/_assets/img/placeholder/videoplaceholderblank.gif'
import iconPlay from '../../Common/_assets/img/icon/icon-play.svg'
// import iconLoad from '../../Common/Assets/img/spinner.gif'

const VideoSinglePageItem = ({s3Url}) => {

  const { Media } = useContext(JewelCtx),
        { Craft } = useContext(JewelCtx),
        { Playlist } = useContext(JewelCtx),
        { Show } = useContext(JewelCtx),
        { Video } = useContext(JewelCtx)

  const match = useRouteMatch(),
        [videoThumbnail, setVideoThumbnail] = useState(null),
        [videoTitle, setVideoTitle] = useState(null),
        [videoDescription, setVideoDescription] = useState(null),
        [videoKey, setVideoKey] = useState(null),
        [bumperVideo, setBumperVideo] = useState(null),
        [videoShows, setVideoShows] = useState(null),
        [videoSlug, setVideoSlug] = useState(null),
        [videoPlaylists, setVideoPlaylists] = useState(null),
        [videoCrafts, setVideoCrafts] = useState(null)




  const videoThumbRef = useRef(),
        videoBumperRef = useRef(),
        videoBumperContainer = useRef(),
        videoFullRef = useRef(),
        videoFullContainer = useRef()


  const vidOpts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
            controls: 1,
            cc_load_policy: 0,
            fs: 1,
            iv_load_policy: 3,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
            playsinline:1
          },
        };

        useLayoutEffect( () => {
            let subscribed = true;
            if (subscribed) {

              // console.log(match.params)
              let currentSlug = match.params.videoSlug
              if (currentSlug) {

                setBumperVideo(null)

                Video('SERIES').load(currentSlug, v => {
                    // console.log('video',v)
                  if (Object.keys(v).length > 0) {
                    // console.log("video single page",v)

                    // if (v.enabled === 1) {
                      setVideoTitle(v.title)
                      setVideoDescription(v.description)
                      setVideoKey(v.video_key)
                      setVideoSlug(match.params.videoSlug)
                    // } else {
                    //   window.location.href='https://fyseetv.netflixawards.com/'
                    // }


                  } else {
                    // window.location.href='https://fyseetv.netflixawards.com/'
                  }

                })
              //
              //   console.log(YouTube.PlayerState)
              }
            } return ()=>{subscribed = false}
              // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [match])


        useLayoutEffect( () => {
            let subscribed = true;
            if (subscribed && videoSlug) {

                Media('VIDEO').getMetaImage(videoSlug, 'thumbnail', image => {
                    if(image && image.show_image) {
                      setVideoThumbnail(s3Url+image.show_image)
                    }
                })

                Show('SERIES').getShowsByVideoSlug(videoSlug, s => {
                  // console.log('getShowsByVideoSlug', s)
                  var result = s.reduce((unique, o) => {
                      if(!unique.some(obj => obj.show_id === o.show_id)) {
                        unique.push(o);
                      }
                      return unique;
                  },[])
                  setVideoShows(result)
                })
                Craft('SERIES').getCrafts(videoSlug, c => {
                  // console.log('getCrafts', c)
                  setVideoCrafts(c)
                })
                Playlist('SERIES').getLists(videoSlug, p => {
                  // console.log('getLists', p)
                  setVideoPlaylists(p[Object.keys(p).length-1])
                })


            } return ()=>{subscribed = false}
              // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [s3Url, videoSlug])


        // useLayoutEffect( () => {
        //     let subscribed = true;
        //     if (subscribed && videoFullRef.current) {
        //           setTimeout(()=>{
        //             playThisVideo()
        //           }, 1000)
        //     } return ()=>{subscribed = false}
        //     // eslint-disable-next-line react-hooks/exhaustive-deps
        // }, [videoFullRef.current])


      useLayoutEffect( () => {
        let subscribed = true;

        if (subscribed) {

            // $(videoThumbRef.current).removeClass('disabled')
            // $(videoThumbRef.current).on("click", function() {
            //         playVideo()
            // });

        } return ()=>{subscribed = false}
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match, videoFullRef])

    const onVideoReady = () => {
      // $(videoFullContainer.current).removeClass('disabled')
      // videoFullRef.current.internalPlayer.playVideo()
      playThisVideo()
    }
    const playThisVideo = () => {
      // $(videoFullContainer.current).removeClass('disabled')
      if ($(videoThumbRef.current).hasClass('disabled')) {
        $(videoThumbRef.current).removeClass('disabled')
      }
      if ($(videoFullContainer.current).hasClass('disabled')) {
        $(videoFullContainer.current).removeClass('disabled')
      }
      videoFullRef.current.internalPlayer.playVideo()
    }

    // const stopVideo = () => {
    //     videoFullRef.current.internalPlayer.stopVideo()
    //     videoFullRef.current.internalPlayer.seekTo(0);
    // }

    const onVideoFullEnd = () => {
       $(videoThumbRef.current).removeClass('disabled')
       $(videoFullContainer.current).addClass('disabled')
       videoFullRef.current.internalPlayer.seekTo(0)
       videoFullRef.current.internalPlayer.stopVideo()
     }




    return (

      <div className=" hero pt-4">

        <div className="d-flex width__ctrl flex-wrap no-gutters mb-5 singlevideo__container p-0">


          <div className="fyseevideo__container col-12 col-md-8 d-flex flex-wrap justify-content-center align-items-center align-self-stretch ">

            <div className="fyseevideo__inner" style={{overflow:'hidden'}}>

              <div ref={videoThumbRef} className="videothumb__container disabled" onClick={()=>{
                playThisVideo()
              }}>
                <div className="">

                    <img src={iconPlay} className="icon__play" alt="FYSEE" />

                    {(videoThumbnail)?(
                        <img src={videoThumbnail} className="videothumbnail__image" alt="FYSEE" />
                    ):(
                        ""
                    )}

                </div>
              </div>

              <div className="videobumper__container disabled" ref={videoBumperContainer}>
                  <div className="video__container">
                      <video id="videoBumper" playsInline className="videobumper__video" ref={videoBumperRef} >
                          <source src={bumperVideo} />
                      </video>
                  </div>
              </div>

              <div className="videofull__container" ref={videoFullContainer}>
                  <div className="video__container">
                      {(videoKey)?(
                          <YouTube
                              id="fyseeyoutubevideo"
                              className="videofull__video"
                              ref={videoFullRef}
                              onReady={onVideoReady}
                              onEnd={onVideoFullEnd}
                              videoId={videoKey}
                              opts={vidOpts} />
                      ):("")}
                      <img src={videoPlaceholderBlank} className="" alt="FYSEE" />
                  </div>
              </div>

            </div>

          </div>

          <div className="col-12 col-md-4 d-flex flex-column no-gutters pl-0 pl-md-4 pb-3 pb-md-0">
            <div className="w-100 pr-4 pb-4 d-flex flex-column h-100">


            <div className="font-weight-bold mt-3">
              <h2 className="d-none d-md-block font-weight-bold"><a href="/">{(videoTitle)?(videoTitle):("")}</a></h2>
              <h3 className="d-block d-md-none font-weight-bold"><a href="/">{(videoTitle)?(videoTitle):("")}</a></h3>
            </div>

            {
              videoDescription ?
              <div className="videofull__description" dangerouslySetInnerHTML={{ __html: videoDescription }}/>
              :""
            }



              <div className="mt-auto">

                {(videoPlaylists)?(
                  <span className="font-weight-bold">
                    <a href={"/playlist/"+videoPlaylists.slug} >{videoPlaylists.name}</a>
                  </span>
                ):("")}

                {(videoShows)?(
                  <span className=" text-muted">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                  ):("")}

                  {
                    videoShows ?
                      videoShows.map((s,i) => {
                        return <VideoShowItem key={i} i={i} show={s} isLinked={true} />
                      })
                    :""
                  }

              </div>


              <div className="mb-0">
                {(videoCrafts)?
                  videoCrafts.map((c,i)=>{
                    if (i===0) {
                      return (
                        <span key={i} className="text-muted  label-small">
                          <a href={"/craft/"+c.slug} >{c.name}</a>
                        </span>
                      )
                    } else {
                      return (
                        <span key={i} className="text-muted  label-small">
                          , <a href={"/craft/"+c.slug} >{c.name}</a>
                        </span>
                      )
                    }
                  })

                  :""}
              </div>






            </div>





          </div>




        </div>

        <div className="width__ctrl bar-red"></div>

      </div>

    )
}

export default VideoSinglePageItem
