import React from 'react'
import CraftMenu from '../../Common/Menus/CraftMenu/CraftMenu.js'
import PlaylistMenu from '../../Common/Menus/PlaylistMenu/PlaylistMenu.js'
import Header from '../../Common/Header/Header.js'
import Footer from '../../Common/Footer/Footer.js'
import VideoSinglePageItem from '../Video/VideoSinglePageItem.js'
// import PlaylistProvider from "../../Common/Context/PlaylistCtx";
// import VideoList from '../Video/VideoList.js'


const VideoSinglePage = (props) => {


    return (

      <div className="wrapper">

      <Header />

      <main className="px-3 px-xl-0">

      <p className="d-none text-center pt-3">VIDEO SINGLE PAGE</p>

      <CraftMenu />


        <VideoSinglePageItem s3Url={props.s3Url} />


    </main>

    <PlaylistMenu />
    <Footer />

  </div>

    )
}

export default VideoSinglePage
