import Api from '../utils/Api'
import { Award } from './Award'
import { Media } from './Media'
import { People } from './People'
import { Video } from './Video'

export const Show = (format) => {
    return {
        list: (cb:any) => {
            Api.get('/' + format.toLowerCase(),
                show => cb(show),
                error => console.error(error) )
        },
        get: (slug:string, cb:any) => {
            Api.get('/' + format + '/' + slug,
                show => cb(show),
                () => {})
        },
        getFeatured: (cb:any) => {
            Api.get('/' + format.toLowerCase()+'/featured',
                show => cb(show),
                error => console.error(error) )
        },
        getGenres: (cb:any) => {
            Api.get('/' + format.toLowerCase()+'/genres',
                genres => cb(genres),
                error => console.error(error) )
        },
        getShowsByGenre: (slug:string, featured:boolean=false, cb:any) => {
            Api.get('/' + format.toLowerCase()+'/genre/'+slug+(featured?'/featured':''),
                genres => cb(genres),
                error => console.error(error) )
        },
        getShowsByVideoSlug: (video_slug:string, cb:any) => {
            Api.get('/' + format.toLowerCase()+'/video/'+video_slug+'/show',
                show => cb(show),
                error => console.error(error) )
        },
        getLinks: (slug:string, cb:any) => {
            Api.get('/'+format+'/'+slug+'/link',
                links => cb(links),
                error => console.log(error) )
        },
        search: (term:string, cb:any) => {
            Api.put('/'+format+'/search', { term: term },
                result => cb(result),
                () => {} )
        },
        Award: Award(),
        Media: Media('SHOW'),
        People: People(),
        Video: Video(format)
    }
}
