import Api from '../utils/Api'

export const Craft = (format) => {
    return {
        getVideos: (craft_slug:string, cb:any) => {

            Api.get('/'+format+'/craft/'+craft_slug+'/video',
                videos => cb(videos),
                () => {} )
        },
        getCrafts: (video_slug:string, cb:any)  => {
            Api.get('/'+format.toLowerCase()+'/video/'+video_slug+'/craft',
                crafts => cb(crafts),
                () => {} )
        }
    }
}
