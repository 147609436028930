import React, { useLayoutEffect, useState } from 'react'
// import { AppContext } from '../../Helpers/Context'
// import { Event, TrackingEvents } from '../../Helpers/Tracking'
// import { Link } from 'react-router-dom'
// import { Row, Col } from 'react-bootstrap'


const CraftMenu = (props) => {


  const [menuOpen, setMenuOpen] = useState(false)


    // const catNavClickLink = (genreName) => {
    //     subNavBlur()
    //     props.genreNameListener(genreName)
    //   Event(TrackingEvents.categories.INTERNAL_NAVIGATION, TrackingEvents.actions.NAV_CATEGORY_SUBNAV, TrackingEvents.labels.NAV_CATEGORY_SUBNAV)
    // }

    useLayoutEffect( () => {
      // let subscribed = true;
      // return ()=>{subscribed=false}
    }, [ props ])

    return (

    <div className="nav__top pt-3">
      <div className="nav__top--inner d-flex width__ctrl flex-wrap no-gutters">
        <div className="d-none d-md-flex col-12 label-small-spaced opacity-35 pb-3">SELECT A CRAFT</div>
        <div onClick={()=>{setMenuOpen(!menuOpen)}} className="cursor-pointer d-flex d-md-none ncol-12 label-small-spaced opacity-35 pb-3 d-flex">SELECT A CRAFT
          {(menuOpen)?(
              <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="ml-1 css-i6dzq1"><polyline points="18 15 12 9 6 15"></polyline></svg>
            ):(
              <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="ml-1 css-i6dzq1"><polyline points="6 9 12 15 18 9"></polyline></svg>
            )}


        </div>
        <div className="d-none d-md-flex col-12 width__ctrl ml-auto justify-content-center justify-content-md-between align-items-center">
          <a href="/craft/directing">Directing</a>
          <a href="/craft/acting">Acting</a>
          <a href="/craft/cinematography">Cinematography</a>
          <a href="/craft/writing">Writing</a>
          <a href="/craft/editing">Editing</a>
          <a href="/craft/hair-makeup">Hair &amp; Makeup</a>
          <a href="/craft/production-design">Production Design</a>
          <a href="/craft/costume-design">Costume Design</a>
          <a href="/craft/animation">Animation</a>
          <a href="/craft/sound-music">Sound &amp; Music</a>
        </div>
        {(menuOpen)?(
          <div className="nav__top--mobile d-flex d-md-none col-12 width__ctrl ml-auto flex-column justify-content-center justify-content-md-between align-items-center">
            <a href="/craft/directing">Directing</a>
            <a href="/craft/acting">Acting</a>
            <a href="/craft/cinematography">Cinematography</a>
            <a href="/craft/writing">Writing</a>
            <a href="/craft/editing">Editing</a>
            <a  className="" href="/craft/hair-makeup">Hair &amp; Makeup</a>
            <a href="/craft/production-design">Production Design</a>
            <a href="/craft/costume-design">Costume Design</a>
            <a href="/craft/animation">Animation</a>
            <a href="/craft/sound-music">Sound &amp; Music</a>
          </div>
        ):("")}

      </div>
    </div>

    )
}

export default CraftMenu
