import React from 'react'

import CraftMenu from '../../Common/Menus/CraftMenu/CraftMenu.js'
import PlaylistMenu from '../../Common/Menus/PlaylistMenu/PlaylistMenu.js'
import Header from '../../Common/Header/Header.js'
import Footer from '../../Common/Footer/Footer.js'
import Hero from '../Hero/Hero.js'
import VideoList from '../Video/VideoList.js'


const Home = ({s3Url}) => {

    return (

      <div className="wrapper flex-column h-100">

        <Header />

        <main className="px-3 px-xl-0 flex-fill">

          <p className="d-none text-center pt-3">HOME PAGE</p>

          <CraftMenu />
          <Hero s3Url={s3Url} />
          <VideoList s3Url={s3Url} />

        </main>

        <PlaylistMenu />
        <Footer />

      </div>

    )
}

export default Home
