import Api from '../utils/Api'

export const Playlist = (format:string) => {
    return {
        getVideos: (list_slug:string, cb:any) => {
            Api.get('/'+format.toLowerCase()+'/playlist/'+list_slug+'/video',
                videos => cb(videos),
                () => {} )
        },
        getLists: (video_slug:string, cb:any) => {
            // console.log("!$!@", '/'+format.toLowerCase()+'/video/'+video_slug+'/list')
            Api.get('/'+format+'/video/'+video_slug+'/list',
                playlists => cb(playlists),
                () => {} )
        }
    }
}
