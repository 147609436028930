import React, { useLayoutEffect,useState, useContext } from 'react'
// import { Event, TrackingEvents } from '../../Common/Helpers/Tracking'
// import { useRouteMatch } from 'react-router-dom'
import {JewelCtx} from "../../Common/Jewel"
import iconPlay from '../../Common/_assets/img/icon/icon-play.svg'

import VideoShowItem from "../Video/Components/VideoShowItem"

const Hero = ({s3Url}) => {

  const { Media } = useContext(JewelCtx),
        { Craft } = useContext(JewelCtx),
        { Playlist } = useContext(JewelCtx),
        { Show } = useContext(JewelCtx),
        { Video } = useContext(JewelCtx)


  const [videoThumbnail, setVideoThumbnail] = useState(null),
        [videoDescription, setVideoDescription] = useState(null),
        [videoTitle, setVideoTitle] = useState(null),
        [videoSlug, setVideoSlug] = useState(null),
        [videoShows, setVideoShows] = useState(null),
        [videoPlaylists, setVideoPlaylists] = useState(null),
        [videoCrafts, setVideoCrafts] = useState(null)


    useLayoutEffect( () => {
        let subscribed = true;
        if (subscribed) {

          Video('SERIES').list(v => {
            setVideoTitle(v[0].title)
            setVideoSlug(v[0].slug)
            setVideoDescription(v[0].description)
          })

        } return ()=>{subscribed = false}
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useLayoutEffect( () => {
        let subscribed = true;
        if (subscribed && videoSlug) {

            Media('VIDEO').getMetaImage(videoSlug, 'thumbnail', image => {
                if(image && image.show_image) {
                  setVideoThumbnail(s3Url+image.show_image)
                }
            })

            Show('SERIES').getShowsByVideoSlug(videoSlug, s => {
              // console.log('getShowsByVideoSlug', s)
              var result = s.reduce((unique, o) => {
                  if(!unique.some(obj => obj.show_id === o.show_id)) {
                    unique.push(o);
                  }
                  return unique;
              },[])
              setVideoShows(result)
            })
            Craft('SERIES').getCrafts(videoSlug, c => {
              // console.log('getCrafts', videoSlug, c)
              setVideoCrafts(c)
            })
            Playlist('SERIES').getLists(videoSlug, p => {
              // console.log('getLists', p)
              setVideoPlaylists(p[Object.keys(p).length-1])
            })


        } return ()=>{subscribed = false}
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [s3Url, videoSlug])





    return (

      <div className="hero pb-3 pb-md-4 pt-4 pt-md-5 ">

        <div className="d-flex width__ctrl no-gutters pb-4 pb-md-5 flex-column-reverse flex-md-row">

          <div className="col-12 col-md-6 d-flex flex-column no-gutters pr-0 pr-md-5">




            <div className="pb-2">
              <h3 className="font-weight-bold"><a href={"/video/"+videoSlug}>{videoTitle}</a></h3>
            </div>

            {
              videoDescription ?
              <div className="videofull__description pb-4" dangerouslySetInnerHTML={{ __html: videoDescription }}/>
              :""
            }


            <div className="mt-2 pb-0 pb-md-0 d-flex align-items-center">




              <div className="mr-auto">

              <div className="">

              {
                videoPlaylists ?
                  <span className="font-weight-bold">
                    {videoPlaylists.name}
                  </span>
                :""
              }

                {(videoShows)?(
                  <span className=" text-muted">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                  ):("")}

                    {
                      videoShows ?
                        videoShows.map((s,i) => {
                          return <VideoShowItem key={i} i={i} show={s} isLinked={true} />
                        })
                      :""
                    }


                </div>

                <div className="mt-0">
                {(videoCrafts)?
                  videoCrafts.map((c,i)=>{
                    if (i===0) {
                      return (
                        <span key={i} className="text-muted  label-small">
                          <a href={"/craft/"+c.slug} >{c.name}</a>
                        </span>
                      )
                    } else {
                      return (
                        <span key={i} className="text-muted  label-small">
                          , <a href={"/craft/"+c.slug} >{c.name}</a>
                        </span>
                      )
                    }
                  })

                  :""}
                </div>

              </div>

              <button className="button__box"><a href={"/video/"+videoSlug}>Watch Now</a></button>


            </div>

          </div>

          <div className="col-12 col-md-6 d-flex flex-wrap justify-content-center align-items-center align-self-stretch position-relative">
          {
            videoThumbnail ?
              <a href={"/video/"+videoSlug}><img alt="Netflix" src={videoThumbnail} className=""/></a>
            :""
          }

            <img src={iconPlay} className="icon__play pointer-none " alt="FYSEE" />
          </div>

        </div>
        <div className="d-none width__ctrl ">

          <div className="d-flex nav__hero col-12  justify-content-center align-items-center align-self-stretch py-3">
            <a href="/" className="px-2 active"><i className="material-icons icon">lens</i></a>
            <a href="/" className="px-2"><i className="material-icons icon">lens</i></a>
            <a href="/" className="px-2"><i className="material-icons icon">lens</i></a>
            <a href="/" className="px-2"><i className="material-icons icon">lens</i></a>
          </div>

        </div>

        <div className="width__ctrl bar-red"></div>

      </div>

    )
}

export default Hero
