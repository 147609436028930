import Api from '../utils/Api'
import { Media } from './Media'
import { Venue } from './Venue'

export const Event = (type:string) => {
    return {
        list: (cb:any) => {
            Api.get('/'+type.toLowerCase()+'/event',
                events => cb(events),
                () => {} )
        },
        getByShow: (show_slug:string, cb:any) => {
            Api.get('/'+type.toLowerCase()+'/'+show_slug+'/event/',
                event => cb(event),
                () => {} )
        },
        getBySlug: (event_slug:string, cb:any) => {
            Api.get('/event/'+event_slug,
                event => cb(event),
                () => {} )
        },
        Media: Media('EVENT'),
        Venue: Venue()
    }
}
