import React from 'react'
// import { PlaylistCtx } from '../../Common/Context/PlaylistCtx'
// import { useRouteMatch } from 'react-router-dom'

import CraftMenu from '../../Common/Menus/CraftMenu/CraftMenu.js'
import PlaylistMenu from '../../Common/Menus/PlaylistMenu/PlaylistMenu.js'
import Header from '../../Common/Header/Header.js'
import Footer from '../../Common/Footer/Footer.js'
import VideoList from '../Video/PlaylistVideoList.js'

const Category = (props) => {




    return (

      <div className="wrapper">

      <Header />

    <main className="px-3 px-xl-0">

      <p className="d-none text-center pt-3">PLAYLIST PAGE</p>

      <CraftMenu />

      <VideoList s3Url={props.s3Url} />

    </main>

    <PlaylistMenu />
    <Footer />

  </div>

    )
}

export default Category
